import { AxiosRequestConfig } from 'axios';
import { AbstractDataState } from './AbstractDataState';
import { SimpleDataSelector } from './createSimpleDataSelector';
import { useGetSimpleApiData } from './useSimpleApiData';
import { useMemo } from 'react';

export function useGetSimpleApiDataWithTransformer<
    TAppThunk,
    TState,
    TBusinessObject,
    TAppErrorCode extends string,
    TTransformedBusinessObject
>(
    fetchData: (requestConfig?: AxiosRequestConfig) => TAppThunk,
    existingDataSelector: SimpleDataSelector<TState, TBusinessObject, TAppErrorCode>,
    dataMapper: (data?: TBusinessObject) => TTransformedBusinessObject,
    alwaysReFetch = false,
    requestConfig?: AxiosRequestConfig,
): AbstractDataState<TTransformedBusinessObject, TAppErrorCode> {
    const existingData = useGetSimpleApiData(fetchData, existingDataSelector, alwaysReFetch, requestConfig);

    return useMemo(() => ({ ...existingData, data: dataMapper(existingData.data) }), [dataMapper, existingData]);
}
