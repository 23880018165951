import { Error } from './Error';

/**
 * Use for cases when the request to Market APIs times out or proxy issues (e.g. DataPower failure)
 */
export type NotReachableMarketApiErrorCode = 'MARKET_API_NOT_REACHABLE';
export type WithNotReachableMarketApiError<TAppErrorCode extends string> =
    | TAppErrorCode
    | NotReachableMarketApiErrorCode;

/**
 * Use for cases when the market API response does not match the expected response structure (e.g. mandatory field missing)
 * Use only if no specific error case can be determined
 */
export type UnexpectedResponseStructureMarketApiErrorCode = 'MARKET_API_UNEXPECTED_RESPONSE_STRUCTURE';
export type WithUnexpectedResponseMarketApiError<TAppErrorCode extends string> =
    | TAppErrorCode
    | UnexpectedResponseStructureMarketApiErrorCode;

/**
 * Use for cases when the market API response does not match the expected response structure (e.g. mandatory field missing)
 * Use only if not specific error case can be determined
 */
export type DefaultBusinessMarketApiErrorCode = 'MARKET_API_DEFAULT_BUSINESS_ERROR';
export type WithDefaultBusinessMarketApiError<TAppErrorCode extends string> =
    | TAppErrorCode
    | DefaultBusinessMarketApiErrorCode;

export type DefaultMarketApiErrors =
    | NotReachableMarketApiErrorCode
    | UnexpectedResponseStructureMarketApiErrorCode
    | DefaultBusinessMarketApiErrorCode;

export type WithDefaultMarketApiErrors<TAppErrorCode extends string> = TAppErrorCode | DefaultMarketApiErrors;

/**
 * Use for cases when something goes wrong in internal logic (e.g. can't find contract with certain start date)
 * Use only for internal business errors, which are not directly related to market responses
 */
export type DefaultInternalBusinessError = 'DEFAULT_INTERNAL_BUSINESS_ERROR';

export type VirusScanErrorCode = 'VIRUS_SCAN_ERROR';

export type ValidationErrorCode = 'VALIDATION_ERROR';

/**
 * Use for cases when the request to Market APIs times out or proxy issues (e.g. DataPower failure)
 */
export const NotReachableMarketApiError: Error<NotReachableMarketApiErrorCode> = {
    status: 502,
    code: 'MARKET_API_NOT_REACHABLE',
    message:
        'Cannot reach the market API. Potential reasons are: market API down, DataPower issues, or, invalid request headers (e.g. auth)',
};

/**
 * Use for cases when the market API response does not match the expected response structure (e.g. mandatory field missing)
 * Use only if no specific error case can be determined
 */
export const UnexpectedResponseStructureMarketApiError: Error<UnexpectedResponseStructureMarketApiErrorCode> = {
    status: 502,
    code: 'MARKET_API_UNEXPECTED_RESPONSE_STRUCTURE',
    message:
        'Market API response does not contain the mandatory fields or the sent fields have not the expected format.',
};

/**
 * Use for cases when the market API response does not match the expected response structure (e.g. mandatory field missing, expected date received number)
 * Use only if no specific error case can be determined and market is not providing a proper error code
 */
export const DefaultBusinessMarketApiError: Error<DefaultBusinessMarketApiErrorCode> = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'The market API responded with an unexpected/ignored error code',
};

export const VirusScanError: Error<VirusScanErrorCode> = {
    status: 422,
    code: 'VIRUS_SCAN_ERROR',
    message: 'At least one of the selected attachments is infected.',
};

export const ValidationError: Error<ValidationErrorCode> = {
    status: 422,
    code: 'VALIDATION_ERROR',
    message: 'At least one of the fields is incorrect.',
};

/**
 * Use for cases when the market API response matches the expected structure but no specific error handling was defined.
 * Use only if no specific error handling is defined by PO, but market provides an error code (e.g. 'AC09')
 * @param marketErrorCode The market API business error code (e.g. 'AC09' in Spain)
 */
export function createDefaultBusinessMarketApiError(
    marketErrorCode?: string,
): Error<DefaultBusinessMarketApiErrorCode> {
    return marketErrorCode
        ? {
              status: 502,
              code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
              message: `The market API responded with an unexpected/ignored error code which is "${marketErrorCode}"`,
          }
        : DefaultBusinessMarketApiError;
}

/**
 * Use for cases when something goes wrong in internal logic (e.g. can't find contract with certain start date)
 * Use only for internal business errors, which are not directly related to market responses
 * @param message Custom message, describing the error
 */
export function createDefaultInternalBusinessError(message: string): Error<DefaultInternalBusinessError> {
    return {
        status: 502,
        code: 'DEFAULT_INTERNAL_BUSINESS_ERROR',
        message: message,
    };
}
