import { useDispatch, useSelector } from 'react-redux';

type SimpleUpdateDataType<TAppThunk, TObject, TSelected> = {
    updateData: (data: TObject) => TAppThunk;
    dataState: TSelected;
};

type UpdateDataType<TBusinessObject, TAppThunk> = (data: TBusinessObject) => TAppThunk;
type SelectorFnType<TState, TSelected> = (state: TState) => TSelected;

export function useGetSimpleUpdateData<TAppThunk, TBusinessObject, TState, TSelected>(
    updateData: UpdateDataType<TBusinessObject, TAppThunk>,
    selector: SelectorFnType<TState, TSelected>,
): SimpleUpdateDataType<TAppThunk, TBusinessObject, TSelected> {
    const dispatch = useDispatch();

    return {
        updateData: (data: TBusinessObject): TAppThunk => dispatch(updateData(data)),
        dataState: useSelector(selector),
    };
}
